
.testimonial {
    border-left: 4px solid #2a255d;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.06);
    padding: 12px 11px 13px 3px;
    margin: 0 15px 30px 15px;
    overflow: hidden;
    position: relative;
}
.testimonial:before{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-top: 25px solid #2a255d;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    transform: rotate(-45deg);
    transform-origin: 80% -30% 0;
}
.testimonial .pic{
    /* display: inline-block; */
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    /* position: absolute; */
    top: 60px;
    left: 20px;
}
.testimonial .pic img{
    width: 100%;
    height: auto;
}
.testimonial .description{
    text-align: justify;
    font-size: 17px;
    font-style: poppins;
    color: black;
    line-height: 25px;
    margin-bottom: 15px;
}
.testimonial .title{
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    color: #2a255d;
    margin: 0;
}
.testimonial .post{
    /* display: inline-block; */
    font-size: 17px;
    color: #2a255d;
}
.owl-theme .owl-controls .owl-page span{
    border: 2px solid #2a255d;
    background: #fff;
    opacity: 1;
}
.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls .owl-page:hover span{
    background: #2a255d;
}
@media only screen and (max-width: 767px){
    .testimonial{
        padding: 20px;
        text-align: center;
    }
    .testimonial .pic{
        display: block;
        position: static;
        margin: 0 auto 15px;
    }
}